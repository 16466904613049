<template>
  <div
    v-if="!loading && visibleData && visibleData.length"
    class="px-4 sm:px-8 lg:px-80 mx-auto"
  >
    <section id="photos" class="my-5 grid sm:grid-cols-2 md:grid-cols-3 gap-8">
      <template v-bind:key="image" v-for="image of visibleData">
        <a @click.prevent="showModal(image)" href="#" class="hover:opacity-75">
          <img
            class="w-full h-auto shadow-md rounded-md object-cover"
            :src="image.thumbs.medium"
            :alt="image.name"
          />
        </a>
      </template>
    </section>
    <p v-if="loading">Still loading..</p>
    <p v-if="error"></p>
  </div>
  <div v-if="!loading && data && data.length" class="px-4 flex justify-center">
    <ul class="flex pl-0 list-none rounded my-2">
      <li
        @click="page > 1 ? setPage(page - 1) : false"
        v-bind:class="{ disabled: page == 1 ? 'disabled' : '' }"
        class="first:ml-0 text-xs font-semibold flex w-20 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500"
      >
        <a :disabled="page == 1" class="page-link" href="#">Previous</a>
      </li>
      <li
        v-bind:key="n"
        v-for="n in parseInt(data.length / perPage) + 1"
        @click="setPage(n)"
        :class="[
          page == n
            ? ['text-white', 'bg-blue-500']
            : ['bg-white', 'text-blue-500'],
        ]"
        class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500"
      >
        <a class="page-link" href="#">{{ n }}</a>
      </li>
      <li
        @click="!lastPage() ? setPage(page + 1) : false"
        v-bind:class="{ disabled: lastPage() ? 'disabled' : '' }"
        class="first:ml-0 text-xs font-semibold flex w-20 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500"
      >
        <a class="page-link" href="#">Next</a>
      </li>
    </ul>
  </div>
  <div :class="{ close: modalVisible ? '' : 'close' }" class="modal bg transition-all" @click="modalVisible = false, modalImage = ''" >
      <img class="content bg-cover" :src="modalImage">
  </div>
</template>

<script>
const perPage = 9;

import { ref, onMounted } from "vue";
export default {
  name: "Gallery",
  methods: {
    lastPage() {
      let numberLastPage = parseInt(this.data.length / perPage) + 1;
      if (this.page >= numberLastPage) {
        return true;
      }
      return false;
    },
    setPage(currentPage) {
      this.page = currentPage;
      currentPage = currentPage - 1;
      let start = perPage * currentPage;
      let end = start + perPage;
      this.visibleData = this.data.slice(start, end);
    },
    showModal(image) {
        this.modalVisible = true;
        this.modalImage = image.url;
        console.log(image)
    }
  },
  setup() {
    const data = ref(null);
    const visibleData = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const modalVisible = ref(false);
    const modalImage = ref(false);
    const modalTitle = ref(false);

    function setPage() {
        modalVisible.value = false;
      visibleData.value = data.value.slice(0, perPage);
    }

    function fetchData() {
      loading.value = true;
      return fetch("https://images.bixxy.net/api", {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then((json) => {
          data.value = json;
        })
        .catch((err) => {
          error.value = err;
          if (err.json) {
            return err.json.then((json) => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          setPage(1);
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchData();
    });

    return {
      data,
      visibleData,
      loading,
      error,
      page: 1,
      perPage: perPage,
      modalVisible,
      modalImage,
      modalTitle,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.active {
  font-weight: bold;
}

.modal.bg {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    opacity: 1;
}
.modal.bg.close {
    opacity: 0;
    pointer-events: none;
}
.modal .content {
    background-color: #fff;
    width: 75vw;
    height: calc((9 / 16) * 75vw);
    border-radius: 10px;
    margin: auto;
    margin-top: calc(50vh - (((9 / 16) * 75vw) / 2));

}

@media only screen and (max-width: 600px) {
    
    .modal .content {
        width: 95vw;
        height: calc((9 / 16) * 95vw);
        margin-top: calc(50vh - (((9 / 16) * 95vw) / 2));
    }
}
</style>
